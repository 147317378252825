<div class="accordion" [ngClass]="{'accordion--opened': isOpened}">
  <input type="checkbox" id="box-{{ id }}" [checked]='isOpened'>
  <div>
    <label for="box-{{ id }}" (click)="isOpened = !isOpened">
      <div class="accordion__title">{{ title }}</div>
      <span class="accordion__arrow">
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L6 6L11 1" stroke="#7E93F8" stroke-width="2"/>
        </svg>
      </span>
    </label>
    <div class="accordion__content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
