import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxsModule} from '@ngxs/store';
import {DeferLoadModule} from '@trademe/ng-defer-load';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {LayoutComponent} from './layout.component';
import {MainMenuComponent} from './main-menu/main-menu.component';
import {FooterComponent} from './footer/footer.component';
import {PublicHeaderComponent} from './public-header/public-header.component';
import {GeneralHeaderComponent} from './header/components/general-header/general-header.component';
import {GeneralHeaderDropdownComponent} from './header/components/general-header-dropdown/general-header-dropdown.component';
import {MessagesModule} from '../messages/messages.module';
import {AuthState} from '../auth/states/auth/auth.state';
import {SubmissionState} from '../shared/microservices/submission/submission.state';
import {DigitalRecordsRequestState} from '../old-modules/mri-records/state/mri-records.state';
import {RtFormsModule} from '../rt-forms/rt-forms.module';
import {IdleService} from './services/idle.service';
import {AccordionComponent} from './footer/accordion/accordion.component';


@NgModule({
  imports: [
    DeferLoadModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    NgxsModule.forFeature([
      AuthState,
      SubmissionState,
      DigitalRecordsRequestState,
    ]),
    RouterModule.forChild([]),
    MessagesModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    MatTooltipModule,
    RtFormsModule,
    NgxPageScrollModule,
  ],
  declarations: [
    LayoutComponent,
    MainMenuComponent,
    FooterComponent,
    PublicHeaderComponent,
    GeneralHeaderComponent,
    GeneralHeaderDropdownComponent,
    AccordionComponent,
  ],
  exports: [
    LayoutComponent,
    MainMenuComponent,
    FooterComponent,
    PublicHeaderComponent,
    GeneralHeaderComponent,
  ],
  providers: [
    IdleService,
  ],
})
export class LayoutModule {
}
