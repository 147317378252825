import {ChangeDetectionStrategy, Component, Input} from '@angular/core';


@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionComponent {
  @Input() title: string;

  id = Math.floor(Math.random() * 1000000);

  isOpened = false;
}
