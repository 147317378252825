import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AliveAwareComponent } from '../../shared/classes/document-click-handler';
import { Observable, Subscription } from 'rxjs';
import { IdleService } from '../services/idle.service';
import { delay } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { AuthState } from '../../auth/states/auth/auth.state';
import { GetStartedService } from '../header/get-started.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit, OnDestroy, AliveAwareComponent  {
  @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;
  @Select(AuthState.isVerified) isVerified$: Observable<boolean>;

  public isAlive = true;

  subscription = new Subscription();

  showFooterImages = false;
  currentYear = new Date().getFullYear();

  constructor(
    private idle: IdleService,
    private cd: ChangeDetectorRef,
    public gs: GetStartedService,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.idle.ready$.pipe(delay(7000)).subscribe(() => {
        if (!this.showFooterImages) {
          this.showFooterImages = true;
          this.cd.detectChanges();
        }
      })
    );
  }

  ngOnDestroy() {
    this.isAlive = false;
    this.subscription.unsubscribe();
  }

}
