<div id="top" #top></div>
<header class="b-container b-header"
  [ngClass]="{
    'dark': theme === 'dark',
    'light': theme === 'light'
  }"
  >
  <div class="logo">
    <a routerLink="/">
      @if (imagingCenterLogoType === 'simon-med' || imagingCenterLogoType === 'centre-lake') {
        <img class="logo__dark hide_if_smaller_960" src="/assets/images/logo-large--black.svg" alt="">
        <img class="logo__light hide_if_smaller_960" src="/assets/images/logo-large--white.svg" alt="">
        <img
          class="brainkey-logo_small hide_if_bigger_959"
          src="/assets/images/logo-small--black.svg"
          alt=""
          >
        <span class="logo__plus">+</span>
        @if (imagingCenterLogoType === 'simon-med') {
          <img
            class="logo__partner image_simon-med"
            src="/assets/images/logo-large--simon-med107.webp"
          srcset="/assets/images/logo-large--simon-med107.webp 1x,
            /assets/images/logo-large--simon-med214.webp 2x,
            /assets/images/logo-large--simon-med321.webp 3x,
            /assets/images/logo-large--simon-med428.webp 4x"
            alt=""
            >
        }
        @if (imagingCenterLogoType === 'centre-lake') {
          <img
            src="/assets/images/logo-large--centre-lake130.webp"
            class="logo__partner image_centre-lake"
          srcset="/assets/images/logo-large--centre-lake130.webp 1x,
            /assets/images/logo-large--centre-lake260.webp 2x,
            /assets/images/logo-large--centre-lake390.webp 3x,
            /assets/images/logo-large--centre-lake520.webp 4x"
            alt=""
            >
        }
      } @else {
        <img class="logo__dark" src="/assets/images/logo-large--black.svg" alt="">
        <img class="logo__light" src="/assets/images/logo-large--white.svg" alt="">
      }
    </a>
  </div>
  <ul class="header-items">
    <li class="header-items__item">
      <a routerLink="/how-it-works">How It Works</a>
    </li>

    <li class="header-items__item">
      <a routerLink="/faq">FAQ</a>
    </li>

    @if (isAuthenticated$ | async) {
      @if (isVerified$ | async) {
        <li class="header-items__item header-items__button">
          <a (click)="gs.navigate()">My Dashboard</a>
        </li>
      } @else {
        <li class="header-items__item header-items__item--get-started">
          <a (click)="gs.navigate()">Get Started</a>
        </li>
        <li class="header-items__item header-items__button">
          <a routerLink="/auth/logout">Log Out</a>
        </li>
      }
    } @else {
      <li class="header-items__item header-items__item--get-started">
        <a (click)="gs.navigate()">Get Started</a>
      </li>
      <li class="header-items__item header-items__button">
        <a routerLink="/auth/sign-in">Log In</a>
      </li>
    }

  </ul>

  <div class="mobile-menu-block">
    <div class="mobile-menu__button" [ngClass]="{'mobile-menu__button--opened': isMobileMenuOpened}" (click)="toggleMenu()">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</header>

<div class="mobile-menu-content" [ngClass]="{'mobile-menu-content--opened': isMobileMenuOpened}">
  <ul>
    <li class="mobile-menu-content__item">
      <app-general-header-dropdown title="About">
        <div class="mobile-menu-content__dropdown">
          <ul>
            <li>
              <a routerLink="/about">Team</a>
            </li>
            <li>
              <a routerLink="/collaborators">Partners</a>
            </li>
          </ul>
        </div>
      </app-general-header-dropdown>
    </li>

    <li class="mobile-menu-content__item">
      <a routerLink="/how-it-works">How It Works</a>
    </li>

    <li class="mobile-menu-content__item">
      <a routerLink="/faq">FAQ</a>
    </li>

    <li class="mobile-menu-content__item mobile-menu-content__login-item">
      @if (isAuthenticated$ | async) {
        @if (isVerified$ | async) {
          <span class="mobile-header__get-started" (click)="gs.navigate()">My Dashboard</span>
        } @else {
          <a routerLink="/auth/logout">Log Out</a>
          <span class="mobile-header__separator">|</span>
          <span class="mobile-header__get-started" (click)="gs.navigate()">Get Started</span>
        }
      } @else {
        <a routerLink="/auth/sign-in">Log In</a>
        <span class="mobile-header__separator">|</span>
        <span class="mobile-header__get-started" (click)="gs.navigate()">Get Started</span>
      }

    </li>
  </ul>
</div>
