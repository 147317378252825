<ul class="main-menu__wrapper">
  <li class="main-menu__item">
  </li>
  <li class="main-menu__item">
    @if (isAuthenticated$ | async) {
      <a
        class="main-menu__link"
        routerLink="/dashboard/"
      >Dashboard</a>
    } @else {
      <a class="main-menu__link" routerLink="/auth/sign-in">Sign In</a>
    }
  </li>
</ul>
