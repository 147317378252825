<div class="accordion" [ngClass]="{'accordion--opened': isOpened}">
  <input type="checkbox" id="box-{{ id }}" [checked]='isOpened'>
  <div class="accordion__container">
    <label for="box-{{ id }}" (click)="isOpened = !isOpened">
      <div class="accordion__title">{{ title }}</div>
      <button class="accordion__button">
        <span></span>
        <span></span>
      </button>
    </label>
    <div class="accordion__content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
