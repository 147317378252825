import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {AliveAwareComponent, DocumentClickHandler} from '../../shared/classes/document-click-handler';
import {AuthState} from '../../auth/states/auth/auth.state';
import {Observable} from 'rxjs';
import {Store, Select} from '@ngxs/store';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuComponent implements OnInit, OnDestroy, AliveAwareComponent {

  public opened: Boolean = false;
  public isAlive = true;
  @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;
  @Select(AuthState.getUserName) userName$: Observable<string>;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    DocumentClickHandler.addHandler({
      component: this,
      handler: (e) => {
        if (this.opened) {
          this.opened = false;
        }
      },
    });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

}
