import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';


type RequestIdleCallbackHandle = any;
type RequestIdleCallbackOptions = {
  timeout: number;
};
type RequestIdleCallbackDeadline = {
  readonly didTimeout: boolean;
  timeRemaining: (() => number);
};

declare global {
  interface Window {
    // @ts-ignore
    requestIdleCallback: ((
      callback: ((deadline: RequestIdleCallbackDeadline) => void),
      opts?: RequestIdleCallbackOptions
    ) => RequestIdleCallbackHandle);
    // @ts-ignore
    cancelIdleCallback: ((handle: RequestIdleCallbackHandle) => void);
  }
}

const requestIdleCallback =
  typeof window !== 'undefined'
    ? window.requestIdleCallback ||
    function(cb: Function) {
      const start = Date.now();
      return setTimeout(function() {
        cb({
          didTimeout: false,
          timeRemaining: function() {
            return Math.max(0, 50 - (Date.now() - start));
          }
        });
      }, 1);
    }
    : () => {};

const cancelIdleCallback =
  typeof window !== 'undefined' ? window.cancelIdleCallback || clearTimeout : () => {};
const observerSupported = () =>
  typeof window !== 'undefined' ? !!(window as any).IntersectionObserver : false;

@Injectable()
export class IdleService {
  private source = new Subject<boolean>();
  ready$ = this.source.asObservable();
  constructor() {
    requestIdleCallback(_ => this.source.next(true));
  }
}
