import {Observable, Subscription} from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Select} from '@ngxs/store';
import {GetStartedService} from '../../get-started.service';
import {WINDOW} from '../../../../universal/window/window.service';
import {AuthState} from '../../../../auth/states/auth/auth.state';


@Component({
  selector: 'app-general-header',
  templateUrl: './general-header.component.html',
  styleUrls: ['./general-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralHeaderComponent implements OnInit, OnDestroy {
  @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;
  @Select(AuthState.isVerified) isVerified$: Observable<boolean>;

  subscription = new Subscription();
  isMobileMenuOpened = false;

  @Input() theme = 'dark';
  @Input() imagingCenterLogoType = '';

  @HostBinding('class.sticky-header') isHeaderFloating = false;

  @ViewChild('top', {static: true}) topAnchor: ElementRef;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.toggleFloating();
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private cd: ChangeDetectorRef,
    private router: Router,
    public gs: GetStartedService
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.isMobileMenuOpened = false;
          this.cd.detectChanges();
        }
      })
    );
  }

  toggleMenu() {
    this.isMobileMenuOpened = !this.isMobileMenuOpened;
    this.cd.detectChanges();
  }

  toggleFloating() {
    const currentScroll = this.window.pageYOffset;
    const anchorPosition = this.topAnchor.nativeElement.offsetTop;

    if (currentScroll > anchorPosition) {
      this.isHeaderFloating = true;
    } else {
      this.isHeaderFloating = false;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
